import { BaseService } from 'app/@core/services/base.service';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function asyncValidator(api: string, bodyData: any, baseService: BaseService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const value = control.value;

    // Make the POST request using the BaseService
    return baseService.postRequest(api, bodyData).pipe(
      map(valueExists => {
        if (valueExists) {
          return { duplicateValue: true };
        }
        return null;
      })
    );
  };
}

@Injectable({
  providedIn: 'root',
})
export class FormValidatorService {
  constructor(
    private baseService: BaseService
  ) {

  }
  specialCharactersValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    const hasSpecialCharacters: boolean = /[!@#$%^&*()?":{}|<>]/.test(value);
    return hasSpecialCharacters ? { specialCharacters: true } : null;
  }

  alphanumericWithSpaceValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value || !value.trim().length) {
      return null
    }
    const isValid: boolean = /^(?=.*[A-Za-z])[A-Za-z0-9\s,\-,._]*$/.test(value);
    return isValid ? null : { alphanumericWithSpace: true };
  }

  numberValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null
    }
    const isValid: boolean = /^[0-9\s]+$/.test(value);

    return isValid ? null : { numberWithoutSpace: true };
  }

  onlyNumericAllowed(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null
    }
    let isValid: boolean = /^[0-9]*$/.test(value);
    return isValid ? null : { onlyNumberNotAllowed: true };
  }

  decimalValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null
    }
    const isValid: boolean = /^-?\d+(\.\d{1,2})?$/.test(value);

    return isValid ? null : { decimal: true };
  }
  positiveDecimalValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null
    }
    const isValid: boolean = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(value);

    return isValid ? null : { positiveDecimal: true };
  }

  blankSpacesValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value || typeof value == 'number') {
      return null;
    }
    return value.startsWith(" ") ? { blankSpaces: true } : null;
  }
  emailValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null
    }
    const isValid: boolean = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/.test(value);
    const endsWithCom: boolean = value.endsWith('.com');

    return isValid && endsWithCom ? null : { email: true };
  }

  uniqueValueValidator(array: any[], fieldName): (control: FormControl) => { [key: string]: any } | null {
    return (control: FormControl): { [key: string]: any } | null => {
      const value: any = control.value;
      const isAlreadyAdded = array.find(item => item[fieldName] == value);
      return isAlreadyAdded ? { duplicateValue: true } : null;
    };
  }

  ayncValidator(api, bodayData): AsyncValidatorFn {
    return (): Observable<{ [key: string]: boolean } | null> => {
      return this.baseService.postRequest(api, bodayData)
        .pipe(
          map(valueExists => {
            if (valueExists) {
              return { duplicateValue: true };
            }
            return null;
          }
          )
        )
    }
  }

  latLongValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (!value) {
      return null
    }
    const isValid: boolean = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/.test(value);

    return isValid ? null : { invalidLatLong: true };
  }

  removeWhiteSpaces(event: any) {
    const inputText = event.target.value;
    const trimmedText = inputText.replace(/\s/g, ''); // Remove spaces
    return trimmedText;
  }

  duplicateDotComValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    if (value.endsWith('.com') && value.indexOf('.com') !== value.lastIndexOf('.com')) {
      return { duplicateDotCom: true };
    }

    return null;
  }
  
  noZeroValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = parseInt(control.value);
    if (value === 0) {
      return { 'zeroValueNotAllowed': true };
    }
    return null;
  }
  

}
