import { Routes } from '@angular/router'
export const Full_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('../../modules/modules.module').then(m => m.ModulesModule)
  },
  { path: 'core-entities', loadChildren: () => import('../../modules/core-entities/core-entities.module').then(m => m.CoreEntitiesModule) },
  { path: 'core-entities/device-management', loadChildren: () => import('../../modules/device-management/device-management.module').then(m => m.DeviceManagementModule) },
  { path: 'core-entities/asset-management', loadChildren: () => import('../../modules/asset-management/asset-management.module').then(m => m.AssetManagementModule) },
  { path: 'access-management', loadChildren: () => import('../../modules/access-management/access-management.module').then(m => m.AccessManagementModule) },
  { path: 'config-management', loadChildren: () => import('../../modules/config-management/config-management.module').then(m => m.ConfigManagementModule) },
  { path: 'device-status', loadChildren: () => import('app/modules/device-status/device-status.module').then(m => m.DeviceStatusModule) },
  { path: 'core-entities/installed-devices', loadChildren: () => import('app/modules/installed-devices/installed-devices.module').then(m => m.InstalledDevicesModule) }
];
