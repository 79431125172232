import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AutoUnsubscribe implements OnDestroy {

private unsubscribe$ = new Subject<void>();

ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}

protected ngOnDestroySubscriptions(...subscriptions: any[]) {
  for (const subscription of subscriptions) {
    if (subscription && typeof subscription.unsubscribe === 'function') {
      subscription.unsubscribe();
    }
  }
}
}