import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { 
    var versionNumber = '1.0';
    var versionNumberStored = localStorage.getItem('version');
    if (localStorage.getItem('authToken') !== null && (versionNumberStored == null) || versionNumberStored !== versionNumber){
      localStorage.clear();
      localStorage.setItem('version',versionNumber)
      window.location.href = 'https://adminportal.e4score.com/login';
      alert("Application will logout. Login again to see latest changes") 
    }
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuth = this.authService.isAuthenticated();
    if (!isAuth) {
      this.router.navigate(['/login']);
    }
    else {
      return true;
    }
  }
}
