import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CSDataTableComponent } from "./cs-datatable.component";
import { ColumnFilterComponent } from "./column-filter/column-filter.component";
import { CommonModule } from "@angular/common";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatSortModule } from '@angular/material/sort';

import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { ColumnManagerterComponent } from "./column-manager/column-manager.component";
import { HeaderTitleComponent } from "../header-title/header-title.component";
import { DisableControlDirective } from "../../directives/disabled.directive";
import { ShowValuePipe } from "./show-value.pipe";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToastrModule } from 'ngx-toastr';
import { DndModule } from 'ngx-drag-drop';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from "@angular/material/paginator";
import { DemoMaterialModule } from 'app/shared/material-module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};


const components = [
  CSDataTableComponent,
  DisableControlDirective,
  ColumnFilterComponent,
  ColumnManagerterComponent,
  HeaderTitleComponent,
  ShowValuePipe
]
@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    DndModule,
    BsDatepickerModule,
    ToastrModule.forRoot(),
    PopoverModule,
    ModalModule.forRoot(),
    NgxFileDropModule,
    MatTableModule,
    DragDropModule,
    MatPaginatorModule,
    MatSortModule,
    DemoMaterialModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  declarations: [
    ...components
  ],
  exports: [...components]
})
export class CSDataTableModule {

}
