<nav class="navbar navbar-expand-lg navbar-success header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>

      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen " id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3 "></i></a> 
        </li>

        <span >Admin Portal</span>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" routerLink="/" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span
                    class="text">APEX</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse " id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <div class="d-flex">
              <div class="navbar-container">
                  <div class="d-block float-right mt-1" data-toggle="collapse">
                    <span class="text-weight-bold">{{ companyName }}</span><br>
                    <span class="font-weight-bold text-muted">{{ userName }} </span>
                    <span class="text-muted" *ngIf="userEmail != null">{{'(' + userEmail + ')'}} </span>
                  </div>
              </div>
              <div>
                <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
                  href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
                  <div class="user d-md-flex d-none mr-2">
                  </div>
                  <img class="avatar" src="assets/img/user-placeholder.jpg" alt="avatar" height="35" width="35" />
                </a>
              </div>
            </div>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item cursor-default" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2"></i><span>Logged In As:</span>
                  <span class="text-capitalize">{{ userName }}</span>
                </div>
              </a>

              <a class="dropdown-item cursor-default" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-award mr-2"></i><span>Company:</span>
                  <span class="text-capitalize">{{ companyName }}</span>
                </div>
              </a> -->

              <a class="dropdown-item" href="javascript:;" (click)="ResetToDefault()">
                <div class="d-flex align-items-center">
                  <i class="ft-refresh-cw mr-2"></i><span>Reset To Default</span>
                </div>
              </a>

              <a class="dropdown-item" href="javascript:;" (click)="onChangePassword()">
                <div class="d-flex align-items-center">
                  <i class="material-icons custom-key-icon">key</i><span>Change Password</span>
                </div>
              </a>
              
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>