import { Injectable, Injector } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpBackend,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  private httpClient: HttpClient;
  constructor(
    handler: HttpBackend,
    public inj: Injector,
    public route: Router
  ) {
    this.httpClient = new HttpClient(handler);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = this.applyCredentials(req);
    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event;
        } else {
          return event;
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private applyCredentials = (req: HttpRequest<any>) => {
    const token = localStorage.getItem('authToken')
    if (token) {
      return req.clone({
        setHeaders: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token,
        },
      });
    } else {
      return req.clone({
        setHeaders: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
    }
  };
}
