import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]'
})
export class DisableControlDirective {

  @Input('appDisableControl') set appDisableControl(value: boolean) {
    const control = this.ngControl.control;
    if (value) {
      control.disable();
    } else {
      control.enable();
    }
  }

  constructor(private ngControl: NgControl) { }

}
