import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment-timezone';
@Pipe({
  name: 'displayDateWithTimezone'
})
export class DisplayDateWithTimezonePipe implements PipeTransform {
  transform(utcDate: string, withtime: string): any {
    let dateTime
    if (!utcDate) {
      return "N/A";
    }
    let timeZone = '';

    if (withtime === 'localTimeZone') {
      let dateTime = moment.utc(utcDate).local().format('MM/DD/YYYY h:mm A z');
      const localtz = moment.tz.guess();
      const shortTimeZoneName = moment.tz(localtz).zoneAbbr();
      dateTime += ` ${shortTimeZoneName}`;
      return dateTime;
    }
    dateTime = moment.tz(utcDate, timeZone).local().format('MM/DD/YYYY');
    if (timeZone) {
      const shortTimeZoneName = moment.tz(timeZone).zoneAbbr();
      dateTime += ` ${shortTimeZoneName}`;
    }

    return dateTime;


    // const localtz11 = moment.utc(utcDate).local().format('MM/DD/YYYY, h:mm:ss A z');
    // const localtz = moment.tz.guess()
    // var testDateUtc = moment.utc(utcDate);
    // var localDate = moment(testDateUtc).local();
    // var llocalDate2ocalDate2 = localDate.format('MM/DD/YYYY, h:mm:ss A z');

    // var localTime = moment.utc(utcDate).toDate();
    // var localTime2 = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
    // dateTime = moment.tz(utcDate, timeZone).local().format('MM/DD/YYYY hh:mm A z');
    // console.log("localtz", llocalDate2ocalDate2, localtz11, localTime2, dateTime);

    // if (withtimeZone === 'localTimeZone') {
    //   timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //   dateTime = moment.tz(utcDate, timeZone).local().format('MM/DD/YYYY hh:mm A z');
    //    dateTime;
    //    console.log("localtz dateTime", dateTime);

    // }
    // dateTime = moment.tz(utcDate, timeZone).local().format('MM/DD/YYYY');
    // return dateTime;

    // return dateTime;
  }
}
